<template>
  <div class="table-responsive adaptive-table sentMessageTable" v-if="list.length > 0">
    <table class="table table-striped table-hover " style="width:100%">
      <thead>
        <tr>
          <th v-for="(item, key) in fields" :class="item === 'text' ? 'col-md-4' : ''" :key="key">{{ $t(item) }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in list" :key="row.id">
          <td :data-label="$t('contact')">
            {{ row.contact }} {{ row.is_email ? '📧' : '📱' }}
          </td>
          <td :data-label="$t('platform')">
            {{ row.platform }}
          </td>
          <td :data-label="$t('sessionExpired')">
            {{ humanDateTime(row.expire_date, $i18n.locale) }}
          </td>
          <td :data-label="$t('date')">
            {{ humanDateTime(row.create_date, $i18n.locale) }}
          </td>
          <td :data-label="$t('actions')">
            <modal-wrapper>
                <template v-slot="{ close }">
                  <confirmation-modal
                    :close="close"
                    :content="$t('confirmDeletion')"
                    @confirm="$.emit('delete', row.id)"
                  ></confirmation-modal>
                </template>
                <template v-slot:trigger="{ open }">
                  <button
                    class="btn btn-danger btn-sm mt-2"
                    :disabled="isDisabledReset"
                    @click="open"
                  >{{ $t('delete') }}</button>
                </template>
              </modal-wrapper>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useHumanDate } from '@/application/composables/humanDate.js'

export default {
  name: 'LookupHistoryTable',
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  emits: ['delete'],
  components: {
    // copyButton: defineAsyncComponent(() => import('@/application/components/elements/CopyButton.vue')),
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
  },
  setup() {
    const { humanDateTime, humanDate } = useHumanDate()

    const fields = ['contact', 'platform', 'sessionExpired', 'date', 'actions']

    return {
      humanDateTime,
      humanDate,
      fields,
    }
  },
}
</script>
